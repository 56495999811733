/* custom-carousel.css */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: #5eead4; /* Change the background color of arrows */
}

.carousel .control-arrow:hover,
.carousel.carousel-slider .control-arrow:hover {
  background: #5eead4; /* Change the background color on hover */
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  color: #ffffff; /* Change the arrow color */
}
