@font-face {
    font-family: 'Rhesmanisa';
    src: url('../public/Rhesmanisa.otf') format('opentype');
}

.title {
    font-family: 'Rhesmanisa', sans-serif;
}

/* Add this to your CSS file */
.hero-section {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  
  .container {
    position: relative;
    z-index: 9;
  }
  
  .title {
    font-size: 4rem;
    font-weight: bold;
    color: white;
  }

  .subtitle {
    font-size: 2rem;
    color: white;
  }
  
  p {
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .about-content p {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  button {
    background-color: white;
    color: teal;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: teal;
    color: white;
  }
  